import type { JSX } from 'react';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
/**
 * Props for `ProductSideBySide`.
 */
export type ProductSideBySideProps =
  SliceComponentProps<Content.ProductSideBySideSlice>;

import { ProductSideBySide } from '@/components';
import { SORT_BY_MAP, stringToSlug, stripHtml } from '@/utils';
import { SortBySelectOption } from '@/types';
import { asText } from '@prismicio/helpers';

/**
 * Component for "ProductSideBySide" Slices.
 */
const ProductSideBySideSlice = ({
  slice,
}: ProductSideBySideProps): JSX.Element | null => {
  const variables = slice.primary;

  const productTitle = asText(variables.title);
  const formatedTitle = stripHtml(productTitle);
  const analyticsListName = stringToSlug(formatedTitle);

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <ProductSideBySide
      analyticsListName={analyticsListName}
      applyShadowToImage={variables.apply_shadow_to_image}
      backgroundSection={variables.background_section}
      filterByBrand={variables.filter_by_brand as string}
      filterByCategory={variables.type as string}
      filterByGroupId={variables.product_group_id as string}
      filterByOnSale={variables.filter_by_on_sale}
      filterByStore={variables.filter_by_store}
      fullWidth={variables.full_width}
      hideOnMobile={variables.hide_image_on_mobile}
      horizontalPosition={variables.horizontal_position}
      image={variables.image}
      imageBackgroundColor={variables.background_color_image}
      imageHeigthOnDesktop={variables.image_height_on_desktop!}
      imageHeigthOnMobile={variables.image_height_on_mobile!}
      imagePosition={variables.image_position}
      limit={variables.limit!}
      paddingSection={variables.padding_section}
      products={(slice as any).products}
      seeAllHref={variables.see_all_link ?? '/'}
      seeAllLinkColor={variables.see_all_link_color}
      seeMoreText={variables.see_all_text as string}
      sortBy={SORT_BY_MAP?.[variables.sort_by] as SortBySelectOption}
      spacingBottom={variables.bottom_spacing}
      title={productTitle}
      titleColor={variables.title_color}
      totalCount={(slice as any).totalCount}
      verticalPosition={variables.vertical_position}
    />
  );
};

export default ProductSideBySideSlice;
